<!--首页-->
<template>
  <div>
  <el-form ref="searchForm"   label-width="240" :inline="true">
    <el-form-item label="选择项目:" prop="name">
      <el-select v-model="article" placeholder="请选择" style="width:240px" clearable>
        <el-option v-for="data in optionsype" :key="data.plot_id" :label="data.plot_name" :value="data.plot_id"></el-option>
      </el-select>
    </el-form-item>
	
	<el-form-item label="时间查询:">
	 <el-date-picker v-model="beginDate" type="date" style="height:35px" value-format="YYYY-MM-DD" ></el-date-picker>
	 <span style="padding: 0 15px;">-</span>
	 <el-date-picker v-model="endDate" type="date" style="height:50px" value-format="YYYY-MM-DD" ></el-date-picker>
	</el-form-item>
	
    <el-form-item><el-button  type="primary" @click="queryMembers()">搜索</el-button></el-form-item>
    <el-form-item><el-button type="danger" @click="reset()" plain>重置</el-button></el-form-item>
	
	 <el-form-item><el-button  type="primary" @click="exportExcel()">导出</el-button></el-form-item>
  </el-form>
<div class="el" style="margin: 0 -10px;">
<div class="el">
 <div class="fl" style="width: 50%;">
	 <div class="el elTableBox">
		<div class="el elT">维修及时率</div>
		<iframe :src="url1" frameborder="0" style="width:100%;height:500px">
		</iframe>
    </div>
 </div> 
 <div class="fl" style="width: 50%;">
	 <div class="el elTableBox">
	 	<div class="el elT">报修状态率</div>
		<iframe :src="url2" frameborder="0" style="width:100%;height:500px">
		</iframe>
		</div>
  </div>
  <div class="clear"></div>
 </div>	 
 <div class="el">
  <div class="fl" style="width: 50%;">
	  <div class="el elTableBox">
		  <div class="el elT">满意度</div>
		  	<iframe :src="url3" frameborder="0" style="width:100%;height:500px">
		  	</iframe>
		</div>
  </div>
  <div class="fl" style="width: 50%;">
  	  <div class="el elTableBox">
  	  		 <div class="el elT">报修统计</div>
		 <iframe :src="url4" frameborder="0" style="width:100%;height:500px">
		 </iframe>
	 </div>
  </div>
  <div class="clear"></div>
  </div>	
  <div class="el">
  <div class="fl" style="width: 50%;">
  	  <div class="el elTableBox">
  	  		 <div class="el elT">报修类型率</div>
			 <iframe :src="url5" frameborder="0" style="width:100%;height:500px">
			 </iframe>
		</div>
  </div>
  <div class="fl" style="width: 50%;">
  	  <div class="el elTableBox">
  	  		 <div class="el elT">项目上报率</div>
		  <iframe :src="url6" frameborder="0" style="width:100%;height:500px">
		  </iframe>
		</div>
  </div>
  <div class="clear"></div>
  <div class="fl" style="width: 50%;">
  	  <div class="el elTableBox">
  	  		 <div class="el elT">维修人数统计</div>
  			 <iframe :src="url7" frameborder="0" style="width:100%;height:500px">
  			 </iframe>
  		</div>
  </div>
  <div class="fl" style="width: 50%;">
  	  <div class="el elTableBox">
  	  		 <div class="el elT">人员绩效评定统计</div>
  		  <iframe :src="url8" frameborder="0" style="width:100%;height:500px">
  		  </iframe>
  		</div>
  </div>
  
  <div class="fl" style="width: 50%;">
  	  <div class="el elTableBox">
  	  		 <div class="el elT">维修量统计</div>
  		  <iframe :src="url9" frameborder="0" style="width:100%;height:500px">
  		  </iframe>
  		</div>
  </div>
  
  <div class="fl" style="width: 50%;">
  	  <div class="el elTableBox">
  	  		 <div class="el elT">
				 当日参与维修人数</div>
		<el-button  type="primary" @click="exportAvgExcel()">导出</el-button>
  		  <el-table :data="tableData" style="width:100%;height:500px">
			<el-table-column prop="time" label="日期" width="100px"/>
			<el-table-column v-for="(item, index) in columns" :key="index"
				:prop="item.name"
				:label="item.name">
			</el-table-column>
			 <!-- <el-table-column prop="电气维修" label="电气维修" />
  		      <el-table-column prop="空调/水暖维修" label="空调/水暖维修" />
  		      <el-table-column prop="综合维修" label="综合维修" />
			  <el-table-column prop="弱电维修" label="弱电维修"/>
			  <el-table-column prop="集中维修" label="集中维修"/>
			  <el-table-column prop="综合维修" label="综合维修" /> -->
			  <el-table-column prop="firstTotal" label="当日维修数量合计" />
			  <el-table-column prop="nextTotal" label="当日参与维修人数" />
			  <el-table-column prop="endTotal" label="日人均维修量" />
  		    </el-table>
  		</div>
  </div>
  
  
  
  <div class="clear"></div>
  </div>
</div>    
  </div>
</template>
<script>
import { reactive, ref, toRefs } from 'vue';
import { ElConfigProvider } from 'element-plus';
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {
  name: 'HomePage',
  setup(){
    let locale = zhCn
    return{
      locale
    }
  },
  created() {
	  var year = new Date().getFullYear();
	  var month = new Date().getMonth() + 1;
	  if(month < 10){
		  month = "0" + month;
	  }
	  var day = new Date().getDate();
	  if(day < 10){
		  day = "0" + day;
	  }
	  this.beginDate = year + "-" + month + "-" + day;
	  this.endDate = year + "-" + month + "-" + day;
	  
    this.handleClickQuery();
    this.getplotname();
  },
  methods: {
	exportAvgExcel(){
		if(this.beginDate!=null&&this.beginDate!=""){
			
		}else{
			this.$message("请输入开始日期")
			return
		}
		if(this.endDate!=null&&this.endDate!=""){
			
		}else{
			this.$message("请输入结束日期")
			return
		}
		
		var req = {};
		req.cmd = "exportAvgExcel";
		req.beginDate = this.beginDate;
		req.endDate = this.addDay(this.endDate,1);
		req.plot_id = this.article;
		this.$sknet.download(this.$skconf("met.surl"), req);
	},
	exportExcel(){
		if(this.beginDate!=null&&this.beginDate!=""){
			
		}else{
			this.$message("请输入开始日期")
			return
		}
		if(this.endDate!=null&&this.endDate!=""){
			
		}else{
			this.$message("请输入结束日期")
			return
		}
		
		var req = {};
		req.cmd = "exportHomePageTotal";
		req.beginDate = this.beginDate;
		req.endDate = this.addDay(this.endDate,1);
		this.$sknet.download(this.$skconf("met.surl"), req);
	},
    handleClickQuery:function(data){
		let _data = "&beginDate="+this.beginDate+"&endDate="+this.addDay(this.endDate,1) + "&plot_id="+this.article
		//this.beginDate != "" && this.endDate != ""
		var _url = this.$skconf("rms.report")
		if(true){
			this.url1 = _url + "/ureport/preview?_u=file:3.17.ureport.xml"+_data;
			this.url2 = _url + "/ureport/preview?_u=file:2.ureport.xml"+_data;
			this.url3 = _url + "/ureport/preview?_u=file:agree.ureport.xml"+_data;
			this.url4 = _url + "/ureport/preview?_u=file:repair.ureport.xml"+_data;
			this.url5 = _url + "/ureport/preview?_u=file:demo1.ureport.xml"+_data;
			this.url6 = _url + "/ureport/preview?_u=file:demo2.ureport.xml"+_data;
			this.url7 = _url + "/ureport/preview?_u=file:weixiu.ureport.xml"+_data;
			this.url8 = _url + "/ureport/preview?_u=file:jixiao.ureport.xml"+_data;
			this.url9 = _url + "/ureport/preview?_u=file:weixiutotal.ureport.xml"+_data;
		}
		this.searchPersonAvgList();
    },
	addDay(datetime, days) {
	          var old_time = new Date(datetime.replace(/-/g, "/")); //替换字符，js不认2013-01-31,只认2013/01/31
	          var fd = new Date(old_time.valueOf() + days * 24 * 60 * 60 * 1000); //日期加上指定的天数
	          var new_time = fd.getFullYear() + "-";
	          var month = fd.getMonth() + 1;
	          if (month >= 10) {
	              new_time += month + "-";
	          } else {
	             //在小于10的月份上补0
	             new_time += "0" + month + "-";
	        }
	         if (fd.getDate() >= 10) {
             new_time += fd.getDate();
	         } else {
	             //在小于10的日期上补0
	             new_time += "0" + fd.getDate();
	        }
	         return new_time; //输出格式：2013-01-02
	},
	queryMembers:function(){
		if(this.beginDate == "" || this.beginDate == null){
			this.$message("请选择开始日期")
			return
		}
		if(this.endDate == "" || this.endDate == null){
			this.$message("请选择结束日期")
			return
		}
		this.$sknet.d
	    this.handleClickQuery();
	},
    getplotname:function(){
      var self = this;
      self.options;
      var redata = {};
      redata.cmd = "getPmsName";
      redata.id=this.ids;
      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
        this.optionsype=datas.datas.items;
      }, function(data) {
        self.$message(data.reason);
      }, self);
    },
	searchPersonAvgList:function(){
	  var self = this;
	  var redata = {};
	  redata.cmd = "searchPersonAvgList";
	  redata.beginDate = this.beginDate;
	  redata.endDate = this.addDay(this.endDate,1);
	  redata.plot_id = this.article;
	  this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
			
		this.tableData = datas.datas.items;
		this.columns = 	this.tableData[0].list;
	  }, function(data) {
	    self.$message(data.reason);
	  }, self);
	},
    reset:function(){
      this.article='';
      this.handleClickQuery();
    }
  },
  data() {
    let self=this;
    return {
      optionsype:'',     //所属项目选项
      article:'',
	  beginDate:'',
	  endDate:'',
	  url1:'',
	  url2:'',
	  url3:'',
	  url4:'',
	  url5:'',
	  url6:'',
	  url7:'',
	  url8:'',
	  url9:'',
	  tableData:[],
	  columns:[]
    }
  },
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },
}
</script>
<style type="text/css">
.el{ width: auto; height: auto; margin: 0;}	
.el.elTableBox{ margin: 0 10px 20px;}
.el.elT{ height: 30px; line-height: 30px; font-size: 16px; text-align: center;}
</style>